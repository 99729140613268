// Generated by Framer (f0205c2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/BaNFXkDYN";

const cycleOrder = ["VWmGo1fSE", "yvEoNn1Sj", "Grr9YFQzz"];

const variantClassNames = {Grr9YFQzz: "framer-v-jcur6d", VWmGo1fSE: "framer-v-hqwb8e", yvEoNn1Sj: "framer-v-nxqch"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "VWmGo1fSE", Mobile: "Grr9YFQzz", Tablet: "yvEoNn1Sj"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "VWmGo1fSE", title: K2qnfJMCo = "Maak boeken eenvoudig", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VWmGo1fSE", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5Fu09", sharedStyle.className, classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-hqwb8e", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"VWmGo1fSE"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({Grr9YFQzz: {"data-framer-name": "Mobile"}, yvEoNn1Sj: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><RichText __framer__animate={{opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, transition: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, x: 0, y: 0}} __framer__animateOnce __framer__enter={{opacity: 0, scale: 1, x: 0, y: 40}} __framer__exit={{opacity: 0, scale: 1, transition: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, x: 0, y: 40}} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-11hnv1e"} data-styles-preset={"BaNFXkDYN"}>Maak boeken eenvoudig</motion.h2></React.Fragment>} className={"framer-7j8uzy"} layoutDependency={layoutDependency} layoutId={"Yd7UpTCpa"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={K2qnfJMCo} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5Fu09 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5Fu09 * { box-sizing: border-box; }", ".framer-5Fu09 .framer-hqwb8e { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 480px; }", ".framer-5Fu09 .framer-7j8uzy { flex: none; height: auto; overflow: visible; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5Fu09 .framer-hqwb8e { gap: 0px; } .framer-5Fu09 .framer-hqwb8e > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-5Fu09 .framer-hqwb8e > :first-child { margin-top: 0px; } .framer-5Fu09 .framer-hqwb8e > :last-child { margin-bottom: 0px; } }", ".framer-5Fu09.framer-v-nxqch .framer-hqwb8e { width: 720px; }", ".framer-5Fu09.framer-v-jcur6d .framer-hqwb8e { width: 390px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 480
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"yvEoNn1Sj":{"layout":["fixed","auto"]},"Grr9YFQzz":{"layout":["fixed","auto"]}}}
 * @framerVariables {"K2qnfJMCo":"title"}
 */
const FramerchiwqhTYg: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerchiwqhTYg;

FramerchiwqhTYg.displayName = "Feature - heading";

FramerchiwqhTYg.defaultProps = {height: 96, width: 480};

addPropertyControls(FramerchiwqhTYg, {variant: {options: ["VWmGo1fSE", "yvEoNn1Sj", "Grr9YFQzz"], optionTitles: ["Desktop", "Tablet", "Mobile"], title: "Variant", type: ControlType.Enum}, K2qnfJMCo: {defaultValue: "Maak boeken eenvoudig", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerchiwqhTYg, [...sharedStyle.fonts])